var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scenarios-table-recursive-component"},[_vm._l((_vm.item.scenarios),function(scenario,indexScenario){return [_c('ScenariosTableRow',{key:'scenario' +
        _vm.item.scenarioId +
        '_' +
        _vm.indexes.indexDataset +
        '_' +
        _vm.indexes.indexBaseline +
        '_' +
        indexScenario,attrs:{"item":scenario,"rowType":"scenario","level":_vm.level,"indexes":_vm.indexes}}),(scenario.expand)?_c('ScenariosTableRecursiveComp',{key:'scenario_recursive_comp' +
        scenario.scenarioId +
        '_' +
        _vm.indexes.indexDataset +
        '_' +
        _vm.indexes.indexBaseline +
        '_' +
        indexScenario +
        '_' +
        _vm.level +
        1,attrs:{"item":scenario,"rowType":"scenario","level":_vm.level + 1,"indexes":_vm.indexes}}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }